import React from "react";
import { Grid, Button } from "@mui/material";
import ExpertIcon from "../../common/icons/ExpertIcon";
import EducationIcon1 from "../../common/icons/EducationIcon1";
import EducationIcon2 from "../../common/icons/EducationIcon2";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import "./services.css";

const ServicesEN = () => {
    const serviceList = [
        {
            title: "24/7 Online Support",
            icon: ExpertIcon,
            text: "Our platform and support team are always active and attentive to your needs."

        },
        {
            title: "Courses and Certifications",
            icon: EducationIcon1,
            text: "Courses and updated material at all times with the latest techniques and equipment."
        },
        {
            title: "Unlimited Access",
            icon: EducationIcon2,
            text: "Start with us and access all the material anytime and as many times as you want."
        },
    ];

    return (
        <section className="section" id="aboutus_gsa">
            <div className="container services-container">
                <Grid container spacing={6} justify="space-between">
                    {serviceList.map((item, ind) => (
                        <Grid key={ind} item sm={4} xs={12}>
                            <div className="text-center max-w-252 mx-auto">
                                <Feature4IconCreator>
                                    <item.icon color="primary" className="text-72" />
                                </Feature4IconCreator>
                                <h5 className="mt-8 font-medium">{item.title}</h5>
                                <p className="max-w-400 mb-6">
                                    {item.text}
                                </p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </section>
    );
};

export default ServicesEN;
