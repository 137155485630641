import React from "react";
import { SvgIcon } from "@mui/material";

const ExpertIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 58.453 72">
      <path
        id="Path_2215"
        data-name="Path 2215"
        d="M175.432,379.617a.9.9,0,0,0,0,1.794h1.4a.9.9,0,1,0,0-1.794Zm0,0"
        transform="translate(-148.497 -322.873)"
      />
      <path
        id="Path_2216"
        data-name="Path 2216"
        d="M1.236,64.884H32.643V63.09H23.726V56.259l10.187-9.906,3.031,1.028.576-1.7-4.271-1.447A9.687,9.687,0,0,0,39.3,33.221l-1.178-5.706a4.106,4.106,0,0,0,1.844-4.478,3.278,3.278,0,0,0-2.131-2.344l.02-5.625a15.023,15.023,0,1,0-30.045-.09l.014,4.84v.021l.019.859a3.28,3.28,0,0,0-2.118,2.339,4.11,4.11,0,0,0,2.244,4.7L6.564,33a9.691,9.691,0,0,0,5.468,11.368l-6.5,2.2A7.646,7.646,0,0,0,.339,53.818V63.987a.9.9,0,0,0,.9.9Zm15.7-20.28,5,4.984V53.73l-8.251-8.024Zm10.68-1.4-4.773,4.76L18.071,43.2V40.967a12.14,12.14,0,0,0,3.542.528h2.459a12.14,12.14,0,0,0,3.542-.528ZM23.726,53.756V49.615L28.751,44.6,32,45.706ZM35.977,40.1a7.87,7.87,0,0,1-6.012,2.967.868.868,0,0,0-.117.011l-.439-.149V40.263a13.66,13.66,0,0,0,4.168-3.157,15.071,15.071,0,0,0,3.387-6.314l.575,2.792a7.871,7.871,0,0,1-1.562,6.52ZM22.829,1.745A13.228,13.228,0,0,1,36.058,15.058l-.017,4.751a16.243,16.243,0,0,1-10.2-4.264,21.314,21.314,0,0,0,5.321-6.373A.9.9,0,1,0,29.6,8.29c-5.006,8.861-12.22,9.263-15.686,9.457l-.051,0A2.543,2.543,0,0,0,11.8,19.287a1.241,1.241,0,0,1-.586.575,1.261,1.261,0,0,1-1.43-.247l-.173-.173L9.6,14.974A13.243,13.243,0,0,1,22.829,1.745ZM7.464,23.463A1.513,1.513,0,0,1,8.835,22.3a.9.9,0,0,0,.8-.713,3.049,3.049,0,0,0,3.785-1.526c.154-.318.4-.516.542-.524l.05,0A20.485,20.485,0,0,0,24.344,16.6a18.041,18.041,0,0,0,11.714,5,.9.9,0,0,0,.791.7,1.514,1.514,0,0,1,1.372,1.158,2.347,2.347,0,0,1,.066.552,2.3,2.3,0,0,1-1.908,2.274.9.9,0,0,0-.742.883v.019a13.157,13.157,0,0,1-3.391,8.71,11.633,11.633,0,0,1-4.525,3.13h-.106v.039a10.356,10.356,0,0,1-3.542.63H21.613a10.358,10.358,0,0,1-3.542-.63v-.039h-.106a11.635,11.635,0,0,1-4.526-3.13,13.157,13.157,0,0,1-3.39-8.71v-.019a.9.9,0,0,0-.742-.883,2.314,2.314,0,0,1-1.843-2.827Zm2,16.576A7.889,7.889,0,0,1,8.3,33.464l.571-2.146a15.057,15.057,0,0,0,3.241,5.788,13.666,13.666,0,0,0,4.168,3.158v2.668l-1.2.407a7.842,7.842,0,0,1-5.61-3.3ZM2.133,53.818a5.853,5.853,0,0,1,3.976-5.546l5.664-1.92,10.16,9.879V63.09H2.133Zm0,0"
        transform="translate(-0.339 0.001)"
      />
      <g
        id="Group_501"
        data-name="Group 501"
        transform="translate(29.754 43.3)"
      >
        <path
          id="Path_2217"
          data-name="Path 2217"
          d="M213.741,289.68a14.35,14.35,0,1,0,14.35,14.35A14.35,14.35,0,0,0,213.741,289.68Zm0,26.906A12.556,12.556,0,1,1,226.3,304.03,12.556,12.556,0,0,1,213.741,316.586Zm0,0"
          transform="translate(-199.391 -289.68)"
        />
        <path
          id="Path_2218"
          data-name="Path 2218"
          d="M258.328,341.283a1.085,1.085,0,0,0-1.081-.572,1.06,1.06,0,0,0-1.065.572l-3.164,10.3a.437.437,0,0,0-.016.143c0,.413.589.7,1.05.7a.542.542,0,0,0,.588-.35l.652-2.257h3.927l.652,2.257a.533.533,0,0,0,.572.35c.477,0,1.066-.3,1.066-.7a.463.463,0,0,0-.032-.143Zm-2.672,7.187,1.59-5.454,1.59,5.454Zm0,0"
          transform="translate(-244.987 -333.083)"
        />
        <path
          id="Path_2219"
          data-name="Path 2219"
          d="M319.155,341.348c-.413,0-.843.159-.843.541v10.525c0,.366.429.556.843.556.43,0,.843-.19.843-.556V341.889C320,341.507,319.585,341.348,319.155,341.348Zm0,0"
          transform="translate(-300.536 -333.625)"
        />
      </g>
    </SvgIcon>
  );
};

export default ExpertIcon;
