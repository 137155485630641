import React, { useEffect } from 'react';
import './modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  const isMobile = window.innerWidth <= 1023;

  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    border: 'solid 3px #3f51b5',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: isMobile ? '70vw' : '30vw',
    height: isMobile ? '30vh' : '40vh',
    zIndex: 999,
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <div style={modalStyle}>
      {children}
    </div>
  );
};

export default Modal;
