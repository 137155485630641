import React, { useState } from "react";
import { Grid, Card, Avatar, Button } from "@mui/material";
import FacebookIcon from "../../common/icons/FacebookIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";
import LinkedinIcon from "../../common/icons/LinkedinIcon";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import './courses.css'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    card: {
        border: "1px solid transparent",
        transition: "all 250ms ease-in-out",
        "&:hover": {
            border: "1px solid rgba(var(--primary), 1)",
            color: palette.primary.main,
            "& $iconContainer": {
                opacity: 1,
            },
            "& .description": {
                color: palette.primary.main,
            },
        },
    },
    iconContainer: {
        position: "absolute",
        top: "calc(50% - 10px)",
        left: "50%",
        transform: "translate(-50%)",
        opacity: 0,
        transition: "opacity 250ms ease",
        "&:after": {
            content: '" "',
            position: "absolute",
            top: 0,
            marginTop: "calc(-50% - 4px)",
            height: 128,
            width: 128,
            background: "rgba(0,0,0, 0.67)",
            borderRadius: 300,
            overflow: "hidden",
            zIndex: -1,
        },
    },
    iconWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        marginRight: 4,
        border: `1px solid ${palette.primary.contrastText}`,
        cursor: "pointer",
    },
}));

const CoursesEN = () => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);

    const HandleShowAll = () => setShowAll(!showAll)
    const speakerList = [
        {
            name: "Open Water Diver",
            imgUrl: "/assets/images/course-img-1.jpeg",
            shortDesc: "Immerse yourself in the underwater world and awaken your passion for the ocean."
        },
        {
            name: "Advanced Open Water Diver",
            imgUrl: "/assets/images/course-img-2.jpeg",
            shortDesc: "Explore new frontiers beneath the sea and challenge your skills."
        },
        {
            name: "Rescue Diver",
            imgUrl: "/assets/images/course-img-3.jpeg",
            shortDesc: "Discover the power of preparation and confidence. Learn rescue techniques."
        },

        {
            name: "First Aid",
            imgUrl: "/assets/images/primeros-auxilios.jpeg",
            shortDesc: "You will learn the necessary techniques to provide life-saving assistance with precision."
        },
        {
            name: "CPR & AED",
            imgUrl: "/assets/images/rcp.jpg",
            shortDesc: "Master the techniques of CPR and AED; you will learn how to respond to cardiac emergencies."
        },
        {
            name: "Medical Oxygen Administration",
            imgUrl: "/assets/images/admin-oxi.jpeg",
            shortDesc: "You will learn how to apply the correct doses and administration techniques."
        },
        {
            name: "Marine Life",
            imgUrl: "/assets/images/vida-ma.jpeg",
            shortDesc: "You will explore and learn about the diverse marine species, their habitat, and behavior."
        },


        {
            name: "Decompression Theory and Dive Computers",
            imgUrl: "/assets/images/teoria-desc.png",
            shortDesc: "Learn the principles of decompression and effectively use dive computers."
        },
        {
            name: "Operation, Maintenance, and Repair of Equipment",
            imgUrl: "/assets/images/reparacion-eq.jpeg",
            shortDesc: " You will learn about the essential components of the equipment, how to perform regular maintenance, and how to troubleshoot common issues that may arise during your dives."
        },
        {
            name: "Nautical Conductor",
            imgUrl: "/assets/images/cond-nautico.jpeg",
            shortDesc: "You will learn about maritime regulations, navigation techniques, water safety, and maneuvering skills."
        },
        {
            name: "Nitrox",
            imgUrl: "/assets/images/nitrox.jpeg",
            shortDesc: "You will learn about the benefits, limitations, and safety considerations associated with Nitrox diving."
        },


        {
            name: "Wreck Diving",
            imgUrl: "/assets/images/naufra.jpeg",
            shortDesc: " You will learn specific navigation and diving techniques for this type of dive, as well as safety protocols and special considerations involved in exploring a shipwreck."
        },
        {
            name: "Side Mount",
            imgUrl: "/assets/images/side-mount.jpg",
            shortDesc: "It will provide you with the skills and knowledge necessary to dive using the sidemount configuration."
        },
        {
            name: "Dive Supervisor",
            imgUrl: "/assets/images/dive-super.jpeg",
            shortDesc: "It will provide you with the knowledge and skills necessary to supervise and manage diving operations safely and efficiently. You will learn about dive planning, organization, and equipment management."
        },
        {
            name: "Dive Master",
            imgUrl: "/assets/images/dive-master.jpeg",
            shortDesc: "You will learn organizational, teaching, and problem-solving skills, as well as how to lead dives and assist in training new divers."
        },


        {
            name: "Instructor Specialist",
            imgUrl: "/assets/images/instruct-spe.jpeg",
            shortDesc: "You will learn advanced teaching, evaluation, and mentoring techniques to help develop skills."
        },
        {
            name: "Instructor Trainer",
            imgUrl: "/assets/images/course-img-4.jpeg",
            shortDesc: "The highest level of certification in recreational diving. It qualifies you to become a Course Director, providing training and certification to other diving professionals."
        },
        {
            name: "Perfect Buoyancy",
            imgUrl: "/assets/images/flotabilidad.jpeg",
            shortDesc: "You will learn to control and adjust your buoyancy precisely, allowing you to maintain a neutral position in the water."
        },
        {
            name: "Dry Suit",
            imgUrl: "/assets/images/traje-seco.jpeg",
            shortDesc: "You will learn how to choose, adjust, and properly use a dry suit, as well as the special considerations involved in its use."
        },



    ];

    return (
        <section id="courses_gsa" className="section section-bg-light-primary">
            <div className="container text-center">
                <h1 className="mt-0 font-normal  text-primary text-44">Our Courses and Certifications</h1>
                <p className="max-w-400 mx-auto mb-16">
                    Access the most comprehensive guide of courses, manuals, and presentations with continuously updated techniques and materials.
                </p>

                <Grid container spacing={3}>


                    {

                        ((!showAll) ? speakerList.slice(0, 4) : speakerList).map((item, ind) => (
                            <Grid key={ind} item sm={3} xs={12}>
                                <Card
                                    elevation={3}
                                    className={clsx(
                                        "border-radius-12 px-6 pt-8 pb-4 text-center",
                                        classes.card
                                    )}
                                >
                                    <div className="mb-6 relative">
                                        <Avatar
                                            src={item.imgUrl}
                                            className="h-128 w-128 inline-block"
                                        ></Avatar>
                                    </div>
                                    <h5 className="m-0 font-medium text-18">{item.name}</h5>
                                    <p className="description">
                                        {item.shortDesc}
                                    </p>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
                <br />
                <div className="text-center">
                    <Button onClick={() => HandleShowAll()} variant="text" color="primary">
                        {(showAll) ? <div>Less</div> : <div>More</div>
                        }                    </Button>
                </div>

            </div>
        </section>
    );
};

export default CoursesEN;
