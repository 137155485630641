import React from "react";
import { SvgIcon } from "@mui/material";

const EducationIcon2 = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 76.612 73">
      <g id="Group_502" data-name="Group 502">
        <path
          id="Path_2222"
          data-name="Path 2222"
          d="M29.357,107.693H66.3a5.616,5.616,0,0,0,5.535-4.712,5.616,5.616,0,0,0,4.69-5.532V88.659a.99.99,0,0,0-1.979,0V97.45a3.633,3.633,0,0,1-3.628,3.628H34.139a3.633,3.633,0,0,1-3.628-3.628V40.3a3.633,3.633,0,0,1,3.628-3.628H62.012v8.459A4.293,4.293,0,0,0,66.3,49.42h8.246v5.3a.99.99,0,1,0,1.979,0V48.43a.979.979,0,0,0,0-.1c0-.019-.005-.038-.009-.058s0-.025-.006-.038-.011-.046-.016-.068,0-.016-.006-.025-.015-.045-.024-.066-.006-.016-.009-.024-.018-.04-.028-.06l-.013-.028c-.009-.018-.02-.035-.031-.052s-.012-.021-.019-.031-.021-.03-.033-.045l-.025-.033c-.011-.014-.023-.027-.035-.04s-.016-.02-.026-.029L63.707,34.989l0,0c-.013-.013-.027-.026-.041-.038l-.03-.027q-.036-.029-.074-.055l0,0A.983.983,0,0,0,63.1,34.7c-.033,0-.066-.005-.1-.005H34.14a5.615,5.615,0,0,0-5.577,5.024,5.615,5.615,0,0,0-4.814,5.55v56.818a5.614,5.614,0,0,0,5.608,5.608ZM63.992,45.131V38.1l9.184,9.34H66.3A2.311,2.311,0,0,1,63.992,45.131Zm-38.264.136a3.634,3.634,0,0,1,2.8-3.533V97.45a5.614,5.614,0,0,0,5.608,5.608H69.8a3.634,3.634,0,0,1-3.5,2.656H29.357a3.633,3.633,0,0,1-3.628-3.628Z"
          transform="translate(-23.749 -34.693)"
        />
        <path
          id="Path_2223"
          data-name="Path 2223"
          d="M162.659,152.434H147.239a.99.99,0,1,0,0,1.979h15.421a.99.99,0,1,0,0-1.979Z"
          transform="translate(-126.045 -133.015)"
        />
        <path
          id="Path_2224"
          data-name="Path 2224"
          d="M101.516,154.413h3.554a.99.99,0,1,0,0-1.979h-3.554a.99.99,0,1,0,0,1.979Z"
          transform="translate(-87.863 -133.015)"
        />
        <path
          id="Path_2225"
          data-name="Path 2225"
          d="M101.516,218.919h9.768a.99.99,0,1,0,0-1.979h-9.768a.99.99,0,1,0,0,1.979Z"
          transform="translate(-87.863 -186.882)"
        />
        <path
          id="Path_2226"
          data-name="Path 2226"
          d="M156.795,282.438a.99.99,0,0,0-.99-.99h-10.85a.99.99,0,1,0,0,1.979h10.85A.99.99,0,0,0,156.795,282.438Z"
          transform="translate(-124.139 -240.751)"
        />
        <path
          id="Path_2227"
          data-name="Path 2227"
          d="M101.516,283.427h3.2a.99.99,0,1,0,0-1.979h-3.2a.99.99,0,1,0,0,1.979Z"
          transform="translate(-87.863 -240.751)"
        />
        <path
          id="Path_2228"
          data-name="Path 2228"
          d="M218.956,347.934h3.593a.99.99,0,1,0,0-1.979h-3.593a.99.99,0,0,0,0,1.979Z"
          transform="translate(-185.934 -294.619)"
        />
        <path
          id="Path_2229"
          data-name="Path 2229"
          d="M101.516,347.934h15.162a.99.99,0,1,0,0-1.979H101.516a.99.99,0,1,0,0,1.979Z"
          transform="translate(-87.863 -294.619)"
        />
      </g>
      <path
        id="Path_2230"
        data-name="Path 2230"
        d="M235.053,177.855V165.17l1.415-.589c1.338-.557,1.537-1.4,1.537-1.85s-.2-1.293-1.537-1.85l-20.485-8.53a7.74,7.74,0,0,0-5.26,0l-20.485,8.53c-1.338.557-1.537,1.4-1.537,1.85s.2,1.293,1.537,1.85l6.882,2.865v10.637a.99.99,0,0,0,.99.99h1.118c3.88,0,7.832,3.607,10.447,5.993,1.813,1.654,2.65,2.386,3.5,2.386s1.627-.689,3.478-2.387c2.6-2.386,6.534-5.992,10.295-5.992h1.178a.99.99,0,0,0,.99-.99V167.643l3.958-1.648v11.761a4.249,4.249,0,1,0,1.979.1Zm-44.109-15.124.054-.023,20.485-8.53a5.774,5.774,0,0,1,3.738,0l20.485,8.53.054.023-.054.023-20.485,8.529a5.776,5.776,0,0,1-3.739,0L191,162.754Zm36.192,14.363h-.188c-4.53,0-8.8,3.919-11.632,6.512a20.4,20.4,0,0,1-2.146,1.824,19.908,19.908,0,0,1-2.163-1.826c-2.841-2.593-7.134-6.51-11.781-6.51H199.1v-8.824l11.624,4.84a7.74,7.74,0,0,0,5.26,0l11.154-4.644Zm6.72,7.13a2.281,2.281,0,0,1-.148-4.556.968.968,0,0,0,.594.037,2.283,2.283,0,0,1-.445,4.519Z"
        transform="translate(-161.495 -132.561)"
      />
    </SvgIcon>
  );
};

export default EducationIcon2;
