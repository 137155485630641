import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
// import clsx from "clsx";
import { Button, Icon, Avatar } from "@mui/material";
import './intro.css'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    introWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "10rem 0px !important",
        margin: "0px"
    }
}));

const Intro = () => {
    const classes = useStyles();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const isMobile = windowWidth <= 767;


    return (
        <section className="section" id="intro_gsa">
            <div className={classes.introWrapper}>

            {isMobile ? (
                    
                    <img
                    className="Intro-backimg"
                        alt=""
                        src="./assets/images/ocean-backhome.jpg"
                    />
                
                ) : (
                    <video
                        className='video-container' id="background-video" autoPlay loop muted poster="./assets/images/ocean-backhome.jpg">
                        <source src="https://res.cloudinary.com/dyjj2eikg/video/upload/v1679510155/gsa/home-video-gsa.mp4" type="video/mp4" />
                    </video>
                )}
                <div className="container" id="Intro-Text-container">
                    <img className="image-logo" src='./assets/images/logo-gsa-completo.png' alt='logo-gsa' />
                    <div className="text-left">
                        <h1 className="title-img">
                            EASY DIVE, EASY LIFE
                        </h1>
                        <p id="image-logo-txt">
                            La mas moderna, joven y flexible certificadora de buceo.<br />
                            Descubre una nueva manera agil e innovadora de enseñanza.
                        </p>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default Intro;
