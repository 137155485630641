import React from "react";
import {
    Grid,
    Card,
    TextField,
    Button,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import './contact.css'
import { send_msg_to_slack } from '../../../api'
import SuccessPopUp from "./successPopUp/index";

const inputProps = {
    style: {
        borderRadius: 8,
    },
};

export default class ContactEN extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstname: '',
            lastname: '',
            subject: '',
            message: '',
            showSuccessPopUp: false, // Add a state variable for the success popup
        }
        this.handleChange = this.handleChange.bind(this)
        this.submitMsg = this.submitMsg.bind(this)
    }

    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    submitMsg() {
        let msg = "subject: " + this.state.subject + "    sender: " + this.state.firstname + "_" + this.state.lastname + "     message: " + this.state.message

        send_msg_to_slack(msg,
            (res) => {
                console.log("message sent")
                this.setState({ showSuccessPopUp: true }); // Set the state to show success popup
            },
            (err) => {
                console.log("error")
                console.log(err)
            })
    }

    render() {
        return (
            <section className="section section-bg-light-primary" id="contact_gsa">
                <div className="container text-center">
                    <h1 className="font-normal text-primary text-48 mb-4">
                        Talk to us
                    </h1>
                    <p className="max-w-400 m-auto mb-16">
                        If you have any doubts, questions, or want to know more about us,
                        leave us a message, and we will get back to you shortly.
                    </p>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item md={6} sm={5} xs={12} className="grid-img-contact">
                            <img
                                src="/assets/images/diver-header-isolated.png"
                                alt="contact"
                                className="w-full max-w-800 mx-auto block img-contact"
                            />
                        </Grid>
                        <Grid item md={6} sm={7} xs={12} >
                            <Card className="p-8 border-radius-8" elevation={3}>
                                <form>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                className="mb-4"
                                                label="First Name"
                                                placeholder="First Name"
                                                size="small"
                                                variant="outlined"
                                                InputProps={inputProps}
                                                fullWidth
                                                id="firstname"
                                                name="firstname"
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                className="mb-4"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                size="small"
                                                variant="outlined"
                                                InputProps={inputProps}
                                                fullWidth
                                                id="lastname"
                                                name="lastname"
                                                onChange={(e) => this.handleChange(e)}

                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                className="mb-4"
                                                label="Email"
                                                placeholder="Subject"
                                                size="small"
                                                variant="outlined"
                                                InputProps={inputProps}
                                                fullWidth
                                                id="subject"
                                                name="subject"
                                                onChange={(e) => this.handleChange(e)}

                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                className="mb-0"
                                                label="Message"
                                                placeholder="Message"
                                                size="small"
                                                variant="outlined"
                                                multiline
                                                rows={8}
                                                InputProps={inputProps}
                                                fullWidth
                                                id="message"
                                                name="message"
                                                onChange={(e) => this.handleChange(e)}

                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                className="mb-0 w-full ml--3"
                                                control={<Checkbox />}
                                                label={
                                                    <p>
                                                        I accept the
                                                        <Link className="text-primary" to="/">
                                                            Terms and Conditions
                                                        </Link>
                                                    </p>
                                                }
                                            />
                                        </Grid>
                                        <p>
                                            o escribemos a <span>
                                                <Link className="text-primary" to="/">
                                                    info@divegsa.com
                                                </Link>
                                            </span>
                                        </p>
                                        <Grid item xs={12}>
                                            <Button
                                                className="w-full"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.submitMsg()}
                                            >
                                                SUBMIT
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Card>
                        </Grid>
                    </Grid>
                    <SuccessPopUp
                        open={this.state.showSuccessPopUp}
                        onClose={() => this.setState({ showSuccessPopUp: false })}
                    />
                </div>
            </section>
        );
    }
}
