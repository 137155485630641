import React, { useState, useEffect } from "react";
import Navbar from "./sections/navbar";
import Intro from "./sections/intro/index";
import Services from "./sections/services/index";
import Story from "./sections/story/index";
import Banner from "./sections/banner/index";
import Admission from "./sections/admission/index";
import Organizations from "./sections/organizations/index";
import Contact from "./sections/contact/index";
import Courses from "./sections/courses/index";
import Footer from "./sections/Footer/index";

import IntroEN from "./sections/intro/indexEN";
import ServicesEN from "./sections/services/indexEn";
import StoryEN from "./sections/story/indexEN";
import BannerEN from "./sections/banner/indexEN";
import AdmissionEN from "./sections/admission/indexEN";
import OrganizationsEN from "./sections/organizations/indexEN";
import ContactEN from "./sections/contact/indexEN";
import CoursesEN from "./sections/courses/indexEN";
import Modal from "./sections/lenguajePopUp/Modal";

const LandingGSA = (props) => {
  const [isSpanish, setIsSpanish] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(true);

  const changeToSpanish = () => {
    setIsSpanish(true);
    setPopupOpen(false);
  };

  const changeToEnglish = () => {
    setIsSpanish(false);
    setPopupOpen(false);
  };

  return (
    <div className="landing">
      
      {isPopupOpen && (
        <Modal isOpen={isPopupOpen} >
            <h2 className="model-h2" >Please Choose a Language</h2>

            <div className="button-lenguage-container">
                <button
                    className="Lenguage-button"
                    onClick={changeToSpanish}
                >
                Español
                </button>
                <button
                    className="Lenguage-button"
                    onClick={changeToEnglish}
                >
                English
                </button>
            </div>    
        </Modal>
        )}


      {/* Resto de la página */}
      <Navbar isSpanish={isSpanish} setIsSpanish={setIsSpanish} />
      {isSpanish ? (
        <>
          <Intro />
          <Services />
          <Story />
          <Courses />
          <Admission />
          <Organizations />
          <Banner />
          <Contact />
        </>
      ) : (
        <>
          <IntroEN />
          <ServicesEN />
          <StoryEN />
          <CoursesEN />
          <AdmissionEN />
          <OrganizationsEN />
          <BannerEN />
          <ContactEN />
        </>
      )}
      <Footer />
    </div>
  );
};

export default LandingGSA;
