import axios from 'axios'
axios.defaults.baseURL = process.env.REACT_APP_GSA_API_URL
axios.defaults.headers.post['Content-type'] = 'application/json' // for POST requests
axios.defaults.headers.post['x-api-key'] = process.env.REACT_APP_GSA_API_KEY // for POST requests
axios.defaults.headers.get['x-api-key'] = process.env.REACT_APP_GSA_API_KEY // for POST requests
axios.defaults.headers.put['x-api-key'] = process.env.REACT_APP_GSA_API_KEY // for POST requests



export function create_instructor(instructor, success, failure) {
    return axios.post('/instructors', instructor)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function update_instructor(instructor, success, failure) {
    return axios.put('/instructors', instructor)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function checkout_instructor(checkout_data, success, failure) {
    const formData = new FormData();

    Object.keys(checkout_data).forEach(function (key, index) {
        console.log(checkout_data[key])
        formData.append(key, checkout_data[key])
    });

    return axios.post('/instructors/checkout', formData)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function get_instructor(success, failure) {
    return axios.get('/users/instructors')
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}
export function get_instructor_by_email(user, success, failure) {
    return axios.get('/instructors?email=' + user.email)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}


export function  create_diver(diver, success, failure) {
    return axios.post('/divers', diver)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function get_diver(success, failure) {
    return axios.get('/divers')
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function create_certification(certification, success, failure) {
    return axios.post('/certifications', certification)
        .then((res) => success(res.data))
        .catch((err) => failure(err))
}


export function get_crossover_payment_link(success, failure) {
    return axios.get('/payments/crossover')
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function get_credential_payment_link(success, failure) {
    return axios.get('/payments/certification')
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}


export function get_divers_by_instructor(instructor, success, failure) {
    return axios.get('/instructors/divers?email=' + instructor.email)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function get_diver_courses(success, failure) {
    return axios.get('/courses')
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function get_diver_by_email(email, success, failure) {
    return axios.get('/divers?email=' + email)
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}

export function send_msg_to_slack(message, success, failure) {

    return axios.post('/messages', {text: message } )
        .then((res) => success(res.data))
        .catch((err) => failure(err));
}


