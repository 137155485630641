import React from "react";
import { Grid, Icon, Button, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import './story.css'
const Story = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <section className="section container-class">
            <div className="container ">
                <Grid container spacing={isMobile ? 4 : 10} justify="space-between">
                    <Grid item sm={6} xs={12}>
                        <img
                            src="/assets/images/story-img.png"
                            alt="graduate"
                            className="max-h-500 max-w-full border-radius-12"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <h1 className="mt-0  text-primary text-48 font-normal mb-8 inline-block">
                            Conocenos...
                        </h1>
                        <p className="my-8 max-w-400">
                            ¿Estás buscando una certificadora de buceo moderna, joven y flexible?
                            <br />
                            <br />
                            Somos la certificadora de buceo más innovadora y adaptable, con un enfoque centrado en nuestros instructores.
                            <br />

                            Nos enorgullece ofrecer un enfoque personalizado en nuestra formación, permitiéndonos adaptarnos a las necesidades individuales de cada buceador.
                            Nuestros servicios ofrecen las últimas técnicas y tecnología para garantizar que nuestros profesionales reciban la mejor atencíon posible.
                            <br />
                            <br />
                            Ofrecemos una amplia gama de cursos de buceo, desde principiantes hasta avanzados, y nos aseguramos de que nuestros profesionales estén cómodos y seguros en todo momento.
                            <br />
                            <br />
                            Credenciales, crossovers e inscripciones a precios accesibles y sin sorpresas! Disponibilidad del material actualizado en todo momento!
                            <br />
                            <br />
                            Si estás buscando una certificación de buceo flexible y moderna, ¡has venido al lugar correcto! Únete a nuestra comunidad de buceadores y descubre el emocionante mundo submarino.
                            <br />
                            <br />
                            ¡Inscríbete hoy y comienza tu aventura de buceo con nosotros!
                        </p>
                        {

                            /*
                            
                            [1, 2, 3].map((item) => (
                                                        <div key={item} className="flex items-center">
                                                            <Icon className="mr-4" color="primary">
                                                                done
                                                            </Icon>
                                                            <p className="my-2">
                                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                            </p>
                                                        </div>
                                                    ))
                            */

                        }
                        <a target="_blank" href="https://app.divegsa.com" rel="noreferrer">
                            <Button
                                className="mt-16 px-7 bg-primary text-white rounded"
                                variant="text"
                                color="primary"

                            >
                                REGISTRATE YA!
                            </Button>
                        </a>

                    </Grid>

                </Grid>
            </div>
        </section>
    );
};

export default Story;
