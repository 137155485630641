import React from "react";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { Button } from "@mui/material";

import './banner.css'
const useStyles = makeStyles(({ palette, ...theme }) => ({
    section: {
        background: "url(./assets/images/image-admission.png) rgba(0,0,0, 0.5)",
        //        backgroundBlendMode: "multiply",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center"

    },
    buttonBorder: {
        border: "1px solid white",
    },
}));

const Banner = () => {
    const classes = useStyles();

    return (
        <section className={clsx("section", classes.section)}>

            <div className="container text-center">
                <img className='img-logo-banner' src='assets/images/logos/logo-gsa.png' alt="" />
                <div className="text-center aling-center text-white">
                    <h1 className="m-0 text-44 font-normal inline-block mx-auto">
                        ESTAS LISTO PARA COMENZAR UNA NUEVA EXPERIENCIA CON NOSOTROS
                    </h1>
                    <p className="my-5 max-w-600 mx-auto text-inherit">
                        Comienza como un profesional GSA y podrás  vivir  increibles experiencias bajo el agua.

                    </p>
                    <a target="_blank" href="https://app.divegsa.com" rel="noreferrer">
                        <Button
                            className="mt-5 px-9 bg-primary text-white rounded banner-button"
                            variant="text"
                            color="primary"
                        >
                            Comienza YA
                        </Button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Banner;
