import { reactLocalStorage } from 'reactjs-localstorage';


export default Storage = {

    set: (k, v) => reactLocalStorage.set(k, v),
    get: (k) => reactLocalStorage.get(k)

}

export function setToken(token) {
    Storage.set("access_token", token)
}

export function getToken() {
    return Storage.get("access_token")
}

export function cleanAuthInfo() {
    reactLocalStorage.remove("auth_info")

}

export function setAuthInfo(obj) {
    cleanAuthInfo();
    reactLocalStorage.setObject("auth_info", obj)
}
export function getAuthInfo() {
    return reactLocalStorage.getObject("auth_info")
}


