import React from "react";
import { Grid, Icon, Button, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";

import './story.css'
const StoryEN = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <section className="section container-class">
            <div className="container ">
                <Grid container spacing={isMobile ? 4 : 10} justify="space-between">
                    <Grid item sm={6} xs={12}>
                        <img
                            src="/assets/images/story-img.png"
                            alt="graduate"
                            className="max-h-500 max-w-full border-radius-12"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <h1 className="mt-0  text-primary text-48 font-normal mb-8 inline-block">
                            Get to know us...
                        </h1>
                        <p className="my-8 max-w-400">
                            Are you looking for a modern, youthful, and flexible scuba certification agency?
                            <br />
                            <br />
                            We are the most innovative and adaptable scuba certification agency, with a focus on our instructors.
                            <br />

                            We take pride in offering a personalized approach to our training, allowing us to tailor our programs to the                            individual needs of each diver. Our services provide the latest techniques and technology to ensure our                         professionals receive the best attention possible.
                            <br />
                            <br />
                            We offer a wide range of diving courses, from beginners to advanced levels, and we make sure our                            professionals are comfortable and safe at all times.
                            <br />
                            <br />
                            Affordable and transparent credentials, crossovers, and enrollments! Updated material is always available!
                            <br />
                            <br />
                            If you are seeking a flexible and modern scuba certification, you've come to the right place! Join our                          community of divers and discover the exciting underwater world.
                            <br />
                            <br />
                            Enroll today and start your diving adventure with us!
                        </p>
                        {

                            /*
                            
                            [1, 2, 3].map((item) => (
                                                        <div key={item} className="flex items-center">
                                                            <Icon className="mr-4" color="primary">
                                                                done
                                                            </Icon>
                                                            <p className="my-2">
                                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                            </p>
                                                        </div>
                                                    ))
                            */

                        }
                        <a target="_blank" href="https://app.divegsa.com" rel="noreferrer">
                            <Button
                                className="mt-16 px-7 bg-primary text-white rounded"
                                variant="text"
                                color="primary"

                            >
                                REGISTER NOW!
                            </Button>
                        </a>

                    </Grid>

                </Grid>
            </div>
        </section>
    );
};

export default StoryEN;
