import React, { useState } from "react";
import { Grid, Card, Avatar, Button } from "@mui/material";
import FacebookIcon from "../../common/icons/FacebookIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";
import LinkedinIcon from "../../common/icons/LinkedinIcon";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import './courses.css'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    card: {
        border: "1px solid transparent",
        transition: "all 250ms ease-in-out",
        "&:hover": {
            border: "1px solid rgba(var(--primary), 1)",
            color: palette.primary.main,
            "& $iconContainer": {
                opacity: 1,
            },
            "& .description": {
                color: palette.primary.main,
            },
        },
    },
    iconContainer: {
        position: "absolute",
        top: "calc(50% - 10px)",
        left: "50%",
        transform: "translate(-50%)",
        opacity: 0,
        transition: "opacity 250ms ease",
        "&:after": {
            content: '" "',
            position: "absolute",
            top: 0,
            marginTop: "calc(-50% - 4px)",
            height: 128,
            width: 128,
            background: "rgba(0,0,0, 0.67)",
            borderRadius: 300,
            overflow: "hidden",
            zIndex: -1,
        },
    },
    iconWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        marginRight: 4,
        border: `1px solid ${palette.primary.contrastText}`,
        cursor: "pointer",
    },
}));

const Courses = () => {
    const classes = useStyles();
    const [showAll, setShowAll] = useState(false);

    const HandleShowAll = () => setShowAll(!showAll)
    const speakerList = [
        {
            name: "Open Water Diver",
            imgUrl: "/assets/images/course-img-1.jpeg",
            shortDesc: "Sumérgete en el mundo submarino y despierta tu pasión por el océano."
        },
        {
            name: "Advanced Open Water Diver",
            imgUrl: "/assets/images/course-img-2.jpeg",
            shortDesc: "Explora nuevas fronteras bajo el mar y desafía tus habilidades."
        },
        {
            name: "Rescue Diver",
            imgUrl: "/assets/images/course-img-3.jpeg",
            shortDesc: "Descubre el poder de la preparación y la confianza. Aprende tecnicas de rescate."
        },

        {
            name: "Primeros Auxilios",
            imgUrl: "/assets/images/primeros-auxilios.jpeg",
            shortDesc: "Aprenderás las técnicas necesarias para brindar asistencia vital con precisión."
        },
        {
            name: "RCP & DEA",
            imgUrl: "/assets/images/rcp.jpg",
            shortDesc: "Domina las técnicas de RCP y DEA, aprenderas como actuar ante emergencias cardíacas."
        },
        {
            name: "Administrador de Oxigeno Medicinal",
            imgUrl: "/assets/images/admin-oxi.jpeg",
            shortDesc: "Aprenderás a aplicar las dosis correctas y sus técnicas de administración."
        },
        {
            name: "Vida Marina",
            imgUrl: "/assets/images/vida-ma.jpeg",
            shortDesc: "Explorarás y aprenderás sobre las diversas especies marinas, su hábitat y su comportamiento."
        },


        {
            name: "Teoria de la Descompresion y Compoutadoras de Buceo",
            imgUrl: "/assets/images/teoria-desc.png",
            shortDesc: "Aprende los principios de descompresión y utilizar de manera efectiva las computadoras de buceo."
        },
        {
            name: "Funcionamiento, Mantenimiento y Reparacion de Equipos",
            imgUrl: "/assets/images/reparacion-eq.jpeg",
            shortDesc: " Aprenderás sobre los componentes esenciales de los equipos, cómo realizar su mantenimiento regular y cómo solucionar problemas comunes que puedan surgir durante tus inmersiones."
        },
        {
            name: "Conductor Nautico",
            imgUrl: "/assets/images/cond-nautico.jpeg",
            shortDesc: "Aprenderás sobre las reglamentaciones marítimas, las técnicas de navegación, la seguridad en el agua y las habilidades de maniobras."
        },
        {
            name: "Nitrox",
            imgUrl: "/assets/images/nitrox.jpeg",
            shortDesc: "Aprenderás sobre los beneficios, las limitaciones y las consideraciones de seguridad asociadas al buceo con Nitrox."
        },


        {
            name: "Naufragios",
            imgUrl: "/assets/images/naufra.jpeg",
            shortDesc: " Aprenderás las técnicas de navegación y buceo específicas para este tipo de inmersiones, así como los protocolos de seguridad y las consideraciones especiales que implica explorar un naufragio."
        },
        {
            name: "Side Mount",
            imgUrl: "/assets/images/side-mount.jpg",
            shortDesc: "Te proporcionara las habilidades y conocimientos necesarios para bucear utilizando la configuración de montaje lateral."
        },
        {
            name: "Dive Supervisor",
            imgUrl: "/assets/images/dive-super.jpeg",
            shortDesc: "Te proporcionara los conocimientos y habilidades necesarios para supervisar y gestionar operaciones de buceo de manera segura y eficiente. Aprenderás sobre la planificación y organización de inmersiones, la gestión de equipos."
        },
        {
            name: "Dive Master",
            imgUrl: "/assets/images/dive-master.jpeg",
            shortDesc: "Aprenderás habilidades de organización, enseñanza y resolución de problemas, así como a guiar inmersiones y asistir en la formación de nuevos buceadores."
        },


        {
            name: "Instructor Specialist",
            imgUrl: "/assets/images/instruct-spe.jpeg",
            shortDesc: "Aprenderás técnicas avanzadas de enseñanza, evaluación y mentoría para ayudar a desarrollar las habilidades."
        },
        {
            name: "Instructor Trainer",
            imgUrl: "/assets/images/course-img-4.jpeg",
            shortDesc: "El nivel más alto de certificación en el buceo recreativo. Te capacita para convertirte en un instructor de instructores, brindando entrenamiento y certificación a otros profesionales del buceo."
        },
        {
            name: "Flotabilidad Perfecta",
            imgUrl: "/assets/images/flotabilidad.jpeg",
            shortDesc: "Aprenderás a controlar y ajustar tu flotabilidad de manera precisa, lo que te permitirá mantener una posición neutral en el agua."
        },
        {
            name: "Traje Seco",
            imgUrl: "/assets/images/traje-seco.jpeg",
            shortDesc: "Aprenderás cómo elegir, ajustar y utilizar adecuadamente un traje seco, así como las consideraciones especiales que implica su uso."
        },



    ];

    return (
        <section id="courses_gsa" className="section section-bg-light-primary">
            <div className="container text-center">
                <h1 className="mt-0 font-normal  text-primary text-44">Nuestros Cursos y Certificaciones</h1>
                <p className="max-w-400 mx-auto mb-16">
                    Accede a la mas completa guia de cursos, guias y presentaciones
                    con tecnicas y materiales continuamente actualizados.
                </p>

                <Grid container spacing={3}>


                    {

                        ((!showAll) ? speakerList.slice(0, 4) : speakerList).map((item, ind) => (
                            <Grid key={ind} item sm={3} xs={12}>
                                <Card
                                    elevation={3}
                                    className={clsx(
                                        "border-radius-12 px-6 pt-8 pb-4 text-center",
                                        classes.card
                                    )}
                                >
                                    <div className="mb-6 relative">
                                        <Avatar
                                            src={item.imgUrl}
                                            className="h-128 w-128 inline-block"
                                        ></Avatar>
                                    </div>
                                    <h5 className="m-0 font-medium text-18">{item.name}</h5>
                                    <p className="description">
                                        {item.shortDesc}
                                    </p>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
                <br />
                <div className="text-center">
                    <Button onClick={() => HandleShowAll()} variant="text" color="primary">
                        {(showAll) ? <div>Ver Menos</div> : <div>Ver Mas</div>
                        }                    </Button>
                </div>

            </div>
        </section>
    );
};

export default Courses;
