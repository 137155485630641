import React from "react";
import { Grid, Button } from "@mui/material";
import ExpertIcon from "../../common/icons/ExpertIcon";
import EducationIcon1 from "../../common/icons/EducationIcon1";
import EducationIcon2 from "../../common/icons/EducationIcon2";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import "./services.css";

const Services9 = () => {
    const serviceList = [
        {
            title: "Soporte online las 24hs",
            icon: ExpertIcon,
            text: "Nuestra platarfoma y nuestro equipo de soporte siempre activos y atentos para tus necesidades."

        },
        {
            title: "Cursos y certificaciones",
            icon: EducationIcon1,
            text: "Cursos y material actulizado en todo momento con las ultimas tecnicas y elementos."
        },
        {
            title: "Acceso Ilimitado",
            icon: EducationIcon2,
            text: "Comienza con nosotros y accede a todo el material en todo momento y cuantas veces quieras."
        },
    ];

    return (
        <section
         className="section" id="aboutus_gsa">
            <div className="container services-container">
                <Grid container spacing={6} justify="space-between">
                    {serviceList.map((item, ind) => (
                        <Grid key={ind} item sm={4} xs={12}>
                            <div className="text-center max-w-252 mx-auto">
                                <Feature4IconCreator>
                                    <item.icon color="primary" className="text-72" />
                                </Feature4IconCreator>
                                <h5 className="mt-8 font-medium">{item.title}</h5>
                                <p className="max-w-400 mb-6">
                                    {item.text}
                                </p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </section>
    );
};

export default Services9;
