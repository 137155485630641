import React from "react";
import { SvgIcon } from "@mui/material";

const EducationIcon1 = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 100.869 72">
      <path
        id="Path_2210"
        data-name="Path 2210"
        d="M439.567,215.907A1.567,1.567,0,0,0,438,217.474v28.762a1.567,1.567,0,0,0,3.134,0V217.474a1.567,1.567,0,0,0-1.567-1.567Z"
        transform="translate(-346.925 -191.7)"
      />
      <path
        id="Path_2211"
        data-name="Path 2211"
        d="M172.577,237.224a1.567,1.567,0,0,0-1.567,1.567V259.61h-1.083c-9.152,0-17.878,8.043-23.652,13.337a33.645,33.645,0,0,1-4.738,3.964c-.823-.336-3.008-2.361-4.78-3.977-5.8-5.293-14.567-13.324-23.96-13.324h-.663V238.567a1.567,1.567,0,1,0-3.134,0v22.692a1.5,1.5,0,0,0,1.567,1.485h2.23c8.178,0,16.4,7.548,21.847,12.514,3.642,3.324,5.319,4.813,6.9,4.813,1.554,0,3.224-1.5,6.849-4.826,5.416-4.967,13.6-12.5,21.536-12.5h2.649a1.5,1.5,0,0,0,1.567-1.485V238.791A1.567,1.567,0,0,0,172.577,237.224Z"
        transform="translate(-91.576 -208.071)"
      />
      <path
        id="Path_2212"
        data-name="Path 2212"
        d="M131.831,129.647c0-.814-.374-2.335-2.877-3.377L86.737,108.692a15.417,15.417,0,0,0-10.477,0L34.044,126.27c-2.5,1.042-2.877,2.563-2.877,3.377s.374,2.334,2.877,3.377L76.26,150.6a15.408,15.408,0,0,0,10.477,0l42.216-17.578C131.457,131.981,131.83,130.46,131.831,129.647Zm-4.082.484L85.533,147.708a12.449,12.449,0,0,1-8.067,0L35.249,130.13a3.081,3.081,0,0,1-.844-.484,3.072,3.072,0,0,1,.844-.484l42.216-17.577a12.449,12.449,0,0,1,8.067,0l42.216,17.577a3.072,3.072,0,0,1,.845.484,3.081,3.081,0,0,1-.845.484Z"
        transform="translate(-31.167 -107.775)"
      />
      <path
        id="Path_2213"
        data-name="Path 2213"
        d="M415.856,345.218a8.309,8.309,0,1,0,8.283,8.309A8.309,8.309,0,0,0,415.856,345.218Zm0,13.483A5.175,5.175,0,1,1,421,353.527a5.175,5.175,0,0,1-5.149,5.174Z"
        transform="translate(-323.269 -292.063)"
      />
    </SvgIcon>
  );
};

export default EducationIcon1;
