import React, { useState, useEffect } from "react";
import { debounce, classList } from "../../../utils";
import { Icon } from "@mui/material";
import { IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import ScrollTo from "../../common/ScrollTo";
import { Button, Switch } from "@mui/material";
import { getAuthInfo, cleanAuthInfo } from "../../storage";
import './navbar.css';

const Navbar = (props) => {
    const [isTop, setIsTop] = useState(true);
    const [isLogged, setIsLogged] = useState(false);
    const [isClosed, setIsClosed] = useState(true);

    const isExternal = (props.isExternal === undefined) ? false : props.isExternal

    useEffect(() => {
        let user = getAuthInfo()
        if (user.email) {
            setIsLogged(true)
        }

    }, []);

    const handleLogout = () => {
        cleanAuthInfo();
        props.history.push('/login');
    }

    let scrollableElement = document.querySelector(".scrollable-content");
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;
    let toggleIcon = isClosed ? "menu" : "close";

    const handleScroll = () => {
        return debounce(({ target: { scrollTop } }) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const close = () => {
        setIsClosed(false);
    };

    useEffect(() => {
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);

    return (
        <section
            className={classList({
                header: true,
                "header-fixed": !isTop,
                closed: isClosed,
            })}

        >
            <div className="container header-container header-container-update">
                <div className="brand">
                    <img src="./assets/images/logos/logo-gsa.png" alt="" />
                </div>
                <div className="nav">
                    <ul className="navigation" style={{ fontSize: 12, }}>
                        <li>
                            {
                                (!isExternal) ? <ScrollTo to="intro_gsa" onScroll={close}>
                                    {props.isSpanish ? "Inicio" : "Home"}
                                </ScrollTo> : <NavLink to="/">{props.isSpanish ? "Inicio" : "Home"}</NavLink>
                            }
                        </li>
                        <li>
                            {
                                (!isExternal) ? <ScrollTo to="aboutus_gsa" onScroll={close}>
                                    {props.isSpanish ? "Nosotros" : "About us"}
                                </ScrollTo> : <NavLink to="/">{props.isSpanish ? "Nosotros" : "About us"}</NavLink>
                            }
                        </li>
                        <li>
                            {
                                (!isExternal) ? <ScrollTo to="courses_gsa" onScroll={close}>
                                    {props.isSpanish ? "Cursos" : "Courses"}
                                </ScrollTo> : <NavLink to="/">{props.isSpanish ? "Cursos" : "Courses"}</NavLink>
                            }
                        </li>
                        <li>
                            {
                                (!isExternal) ? <ScrollTo to="admission_gsa" onScroll={close}>
                                    {props.isSpanish ? "Admisión" : "Admission"}
                                </ScrollTo> : <NavLink to="/">{props.isSpanish ? "Admisión" : "Admission"}</NavLink>
                            }
                        </li>
                        <li>
                            {
                                (!isExternal) ? <ScrollTo to="contact_gsa" onScroll={close}>
                                    {props.isSpanish ? "Contacto" : "Contact"}
                                </ScrollTo> : <NavLink to="/">{props.isSpanish ? "Contacto" : "Contact"}</NavLink>
                            }
                        </li>
                        <li>
                            <NavLink to="https://app.divegsa.com" >
                                <Button
                                    style={{ fontSize: "9.5px", maxWidth: '100%' }}
                                    className="box-shadow-none px-8 hover-bg-warning"
                                    variant="outlined"
                                    color={(isLogged) ? "primary" : "warning"}
                                >
                                    {props.isSpanish ? "Encontra tu certificacion" : "Find your certification"}
                                </Button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/" >
                                <Button
                                    style={{ fontSize: "9.5px", maxWidth: '100%' }}
                                    className="box-shadow-none px-8  hover-bg-warning"
                                    variant="outlined"
                                    color={(isLogged) ? "primary" : "warning"}
                                >
                                    {props.isSpanish ? "Reemplaza tu credencial" : "Replace your card"}
                                </Button>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div>
                </div>

                {(isLogged) ?
                    <div className="navigation flex">
                        <NavLink to="/dashboard" className="mr-1">
                            <Button
                                className="box-shadow-none px-8 rounded-l hover-bg-warning capitalize"
                                variant="outlined"
                                color="warning"
                                style={{ fontSize: 11.5, marginTop: 1 }}
                            >
                                Dashboard
                            </Button>
                        </NavLink>
                        <NavLink to="/login">
                            <Button
                                className="box-shadow-none px-8 rounded-r hover-bg-warning capitalize"
                                variant="outlined"
                                color="warning"
                                style={{ fontSize: 11.5, marginTop: 1 }}
                                onClick={() => handleLogout()}
                            >
                                Logout
                            </Button>
                        </NavLink>
                    </div> :
                    <div className="navigation flex">
                        <a target="_blank" href="https://app.divegsa.com" rel="noreferrer" className="mr-1">
                            <Button
                                className="box-shadow-none px-7 rounded-l hover-bg-primary capitalize"
                                variant="outlined"
                                color="primary"
                                style={{ fontSize: 11.5, marginTop: 1 }}
                            >
                                Login
                            </Button>
                        </a>
                        <a target="_blank" href="https://app.divegsa.com" rel="noreferrer">
                            <Button
                                className="box-shadow-none px-7 rounded-r hover-bg-primary capitalize"
                                variant="outlined"
                                color="primary"
                                style={{ fontSize: 11.5, marginTop: 1 }}
                            >
                                Signup
                            </Button>
                        </a>
                    </div>}
                <IconButton
                    className="header__toggle"
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    <Icon>{toggleIcon}</Icon>
                </IconButton>

            </div>
        </section>
    );
};

export default Navbar;
