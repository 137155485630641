import React from "react";
import {
  Route,
  Routes,
  Navigate,
  HashRouter as Router
} from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';

import { ThemeProvider } from '@mui/material/styles';
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";

import "react-perfect-scrollbar/dist/css/styles.css";
import GlobalCss from "./styles/jss/GlobalCss";
import LandingGSA from "./home/LandingGSA";


function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalCss>
        <Scrollbar
          className="h-full-screen scrollable-content"
          option={{ suppressScrollX: true }}
        >
          <Router>
            <Routes>
              <Route path="/gsa" element={<LandingGSA />} />
              <Route path="/" exact to="gsa" element={<LandingGSA />} />
              {/* <Route component={Error} /> */}
            </Routes>
          </Router>
        </Scrollbar>
      </GlobalCss>
    </ThemeProvider>
  );
}

export default App;
