import './successpopup.css';
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function SuccessPopUpESP(props) {
  const modalStyles = {
    overflow: "hidden",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10000,
    width: window.innerWidth <= 720 ? "80%" : "35%",
    'background-color': '#3f51b5',
    'border-radius': '20px',

  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={props.open} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="SuccessPopUp-Body">
          <div className="SuccessPopUp-Header">
            <AiOutlineCloseCircle className="Close-ButtonSuccessPopup" onClick={handleClose} />
          </div>
          <p>
            Mensaje enviado <span>correctamente!</span>
          </p>
        </div>
      </Modal>
    </>
  );
}

export default SuccessPopUpESP;
