import React from "react";

import { makeStyles } from '@mui/styles';
import { lighten } from "@mui/material";

import clsx from "clsx";
import './organizations.css'
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    card: {
        transition: "all 250ms ease-in-out",
        border: `1px solid ${palette.primary.contrastText}`,
        "& [class^='MuiAvatar-root']": {
            border: `1px solid ${palette.primary.main}`,
            background: palette.primary.contrastText,
            transition: "all 250ms ease-in-out",
        },
        "& .divider": {
            transition: "all 250ms ease-in-out",
        },
        "&:hover": {
            border: `1px solid ${palette.primary.main}`,
            "& [class^='MuiAvatar-']": {
                background: lighten(palette.primary.main, 0.75),
            },
            "& [class^='MuiSvgIcon-']": {
                color: `${palette.primary.main} !important`,
            },
            "& .divider": {
                background: `rgba(var(--primary),0.54) !important`,
            },
        },
    },
}));

const OrganizationsEN = () => {
    const classes = useStyles();

    const organizationList = [
        {
            companyLogoUrl: "./assets/images/wrstc.png",
            widthImg: "40%"
        },
        {
            companyLogoUrl: "./assets/images/dan-logo.jpeg",
            widthImg: "54%"

        },
        {
            companyLogoUrl: "./assets/images/isac-logo.png",
            widthImg: "54%"

        },
        {
            companyLogoUrl: "./assets/images/euf-logo.png",
            widthImg: "30%"

        },
        {
            companyLogoUrl: "./assets/images/cmas-logo.jpeg",
            widthImg: "30%"

        },
        {
            companyLogoUrl: "./assets/images/erstc-logo.png",
            widthImg: "54%"

        },
        {
            companyLogoUrl: "./assets/images/ds-logo.png",
            widthImg: "30%"

        },
        {
            companyLogoUrl: "./assets/images/adci-logo.png",
            widthImg: "25%"

        },

    ];
    //font-normal text-primary text-48 mb-4
    return (
        <div className="section" id="testimonial1">
            <div className="container">
                <div className="mb-16 text-center mx-auto">
                    <h1 className="font-normal text-primary text-44 mb-4">
                        A SAFER DIVING EXPERIENCE
                    </h1>
                    <p className="max-w-400 mx-auto">
                        Collaborating with them and working together to create a serious, professional, and safe environment.
                    </p>
                    <br />

                    <Grid container>

                        <Grid item xs={4}>
                            <img src={organizationList[1].companyLogoUrl} width={organizationList[1].widthImg} />
                        </Grid>
                        <Grid item xs={4}>
                            <img src={organizationList[2].companyLogoUrl} width={organizationList[2].widthImg} />

                        </Grid>
                        <Grid item xs={4}>
                            <img src={organizationList[3].companyLogoUrl} width={organizationList[3].widthImg} />

                        </Grid>
                        <Grid item xs={4}>
                            <img src={organizationList[4].companyLogoUrl} width={organizationList[4].widthImg} />

                        </Grid>


                        <Grid item xs={4}>
                            <img src={organizationList[6].companyLogoUrl} width={organizationList[6].widthImg} />
                        </Grid>
                        <Grid item xs={4}>
                            <img src={organizationList[7].companyLogoUrl} width={organizationList[7].widthImg} />
                        </Grid>
                    </Grid>
                </div>


            </div>
        </div>
    );
};

export default OrganizationsEN;
